import React from 'react'
import Layout from '../components/layout'

import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>This page does not exist. <Link to='/'>Return home</Link>.</p>
  </Layout>
)

export default NotFoundPage
